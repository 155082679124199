// Utilities
import { make } from 'vuex-pathify'

const state = {
  product: null,
  cartCount: 0,
  page:1,
  productInfos:[],
  totalPage:0,
  totalCount:0,
  scrollPosition:0,
}

const getters = {
  ...make.getters(state),
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
